










































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LandingPreview from '@/modules/landingpages/components/LandingPreview/Index.vue'
import PageEmailModal from '@/modules/landingpages/components/PageEmailModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsPageCard from '@/modules/landingpages/components/VsPageCard/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsEmptyState from '@/components/VsEmptyState/Index.vue'
import {
    checkCreatePage,
    checkPublishPage,
    previewLive,
    getPages,
    deletePage,
    duplicatePage,
    changeStatusPage,
    preview,
} from '@/api/landingpage/pages'
import {
    IPage,
    PageStatus,
} from '@/api/landingpage/types/pages.types'
import { exportToZip } from '@/utils/exportToZip'
import { get } from 'lodash'
import { VsToastAspectEnum, VsToastOptionsObjectInterface } from '@advision/vision/src/components/VsToast/types'
import { AppModule } from '@/store/modules/app'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'LandingpagesCards',
    components: {
        LandingPreview,
        PageEmailModal,
        VsDropdownButton,
        VsSectionHeader,
        VsConfirm,
        VsPageCard,
        VsContainer,
        VsEmptyState,
    },
})
export default class extends Vue {
    private showPreview = false
    private cardsLoading = false
    private firsloader = true
    private previewContent = ''
    private emptyState = true
    private debounce: any = undefined
    private pages: IPage[] = []
    private showAlert = false
    private canCreate = false
    private canPublish = false
    private pagination = {
        totalPage: 1,
        page: 1,
        limit: 5,
        skip: 0,
        total: 0,
    }

    private filters: any = {
        status: null,
        search: null,
        sortBy: null,
    }

    $refs!: {
        deleteConfirm: VsConfirm
        PageEmailModal: PageEmailModal
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canExportHtml () {
        return this.userRules.landingpage.export
    }

    get canManageSubdomain () {
        return this.userRules.landingpage.subdomain
    }

    get hasShop () {
        return this.userRules.shop
    }

    get pageAbortController () {
        return AppModule.pageAbortController
    }

    private async checkCreatePageAndRedirect () {
        try {
            await checkCreatePage()
            this.$router.push({ name: 'CreatePage' })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t(`landingpages.CreatePage.errors.PagesLimitReached`).toString(),
                primaryAction: this.hasShop ? this.$t(`landingpages.CreatePage.errors.linkAction`).toString() : '',
                primaryActionCallback: this.redirectToShopPrices,
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    async mounted () {
        await this.initialize(true)
        this.checkPermissionBanner()
        this.firsloader = false
    }

    private redirectToShopPrices () {
        if (this.hasShop) {
            this.$router.push({
                name: 'prices',
            })
        }
    }

    async exportToZip (page: any): Promise<void> {
        if (this.canExportHtml) {
            try {
                const res = await previewLive({
                    pageId: page._id,
                    html: page.project.html,
                    css: page.project.css,
                })
                const previewContent = res.data
                exportToZip(previewContent)
            } catch (e) {
                console.log(e)
                this.$root.$vsToast({
                    heading: this.$t(`landingpages.pages.pageExportErrors.default`).toString(),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 5000,
                })
            }
        } else {
            this.$root.$vsOpenBlockedModal()
        }
    }

    private async checkPermissionBanner () {
        let showAlert = false
        try {
            await checkCreatePage()
            this.canCreate = true
        } catch (e) {
            showAlert = true
            this.canCreate = false
            console.log(e)
        }
        try {
            await checkPublishPage()
            this.canPublish = true
        } catch (e) {
            showAlert = true
            this.canPublish = false
            console.log(e)
        }
        this.showAlert = showAlert
    }

    @Watch('pagination.page')
    async setSkip (val: number): Promise<void> {
        const skip = (val - 1) * this.pagination.limit
        this.pagination.skip = skip
        await this.initialize()
    }

    @Watch('pagination.total')
    private setTotalPage (val: number): void {
        if (val === 0) {
            this.pagination.totalPage = 1
            this.pagination.skip = 0
            this.pagination.page = 1
            return
        }
        this.pagination.totalPage = Math.ceil(val / this.pagination.limit)
    }

    @Watch('filters', { deep: true })
    private setFilters () {
        this.pagination.skip = 0
        this.pagination.page = 1
        this.initialize()
    }

    async initialize (checkEmpty?: boolean): Promise<void> {
        this.cardsLoading = true
        try {
            const sortBy = this.filters.sortBy || '-|createdAt'
            const sortSplit = sortBy.split('|')
            const query: any = {
                limit: this.pagination.limit,
                skip: this.pagination.skip,
                populate: 'project',
                status: this.filters.status,
                sort: `${sortSplit[0]}${sortSplit[1]}`,
            }
            if (this.filters.search) {
                query.name = `/${this.filters.search}/i`
            }
            const response = await getPages(query, this.pageAbortController?.signal)
            this.pages = response.data.docs
            this.pagination.limit = response.data.limit
            if (response.data.skip >= response.data.count) {
                this.pagination.skip = 0
                this.pagination.page = 1
            } else {
                this.pagination.skip = response.data.skip
            }

            this.pagination.total = response.data.count
            if (checkEmpty && response.data.count > 0) this.emptyState = false
        } catch (e) {
            this.pages = []
            console.log(e)
        }
        this.cardsLoading = false
    }

    async deleteItem (id: string): Promise<void> {
        try {
            await this.$refs.deleteConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deletePage(id)
            this.initialize()
            this.checkPermissionBanner()
            this.$root.$vsToast({
                heading: this.$t('landingpages.pages.pageDeleteMessage').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t('landingpages.pages.pageDeleteMessageError').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async duplicatePage (id: string): Promise<void> {
        this.cardsLoading = true
        try {
            await duplicatePage(id)
            this.$root.$vsToast({
                heading: this.$t('landingpages.pages.pageDuplicatedMessage').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
            this.initialize()
            this.checkPermissionBanner()
        } catch (e) {
            const responseMessage = get(e, 'response.data.message', 'default')
            const toastOption: VsToastOptionsObjectInterface = {
                heading: this.$t(`landingpages.pages.pageDuplicatedErrors.${responseMessage}`).toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            }
            if (this.hasShop && (responseMessage === 'PagesLimitReached' || responseMessage === 'PagesPublicReached')) {
                toastOption.primaryAction = this.$t(`landingpages.CreatePage.errors.linkAction`).toString()
                toastOption.primaryActionCallback = this.redirectToShopPrices
            }

            this.$root.$vsToast(toastOption)
            this.cardsLoading = false
        }
    }

    sendPagePreview (id: string) {
        try {
            this.$refs.PageEmailModal.open(id)
        } catch (e) {
            console.log(e)
        }
    }

    async changeStatus (value: PageStatus, id: string): Promise<void> {
        try {
            const res = await changeStatusPage(
                { status: value },
                id,
            )

            if (res.data.nModified > 0) {
                this.initialize()
                this.checkPermissionBanner()
            }
            this.$root.$vsToast({
                heading: this.$t('landingpages.pages.pageStatusMessage').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            const responseMessage = get(e, 'response.data.message', 'default')
            const toastOption: VsToastOptionsObjectInterface = {
                heading: this.$t(`landingpages.pages.pageStatusErrors.${responseMessage}`).toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            }
            if (this.hasShop && (responseMessage === 'PagesLimitReached' || responseMessage === 'PagesPublicReached')) {
                toastOption.primaryAction = this.$t(`landingpages.CreatePage.errors.linkAction`).toString()
                toastOption.primaryActionCallback = this.redirectToShopPrices
            }

            this.$root.$vsToast(toastOption)
            this.cardsLoading = false
        }
    }

    private async runEditorPreview (page: any) {
        this.showPreview = true
        try {
            await this.preview(page._id)
        } catch (error) {
            this.showPreview = false
        }
    }

    private closePreviewDialog (): void {
        this.showPreview = false
        this.previewContent = ''
    }

    private async preview (pageId: string) {
        try {
            const res = await preview(pageId)
            this.previewContent = res.data
        } catch (e) {
            this.previewContent = ''
            console.log(e)
        }
    }
}
