var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsListingCard',{scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('VsImage',{staticClass:"vs-rounded-small",staticStyle:{"width":"126px"},attrs:{"src":_vm.page.project && _vm.page.project.previewImage ? _vm.page.project.previewImage : '',"enableHover":true}},[_c('VsButton',{attrs:{"variant":"icon","aspect":"ghost","iconName":"zoom-in","size":"large"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('preview')}}})],1)]},proxy:true},{key:"accessories",fn:function(){return [_c('VsBadge',{staticClass:"vs-mr-2 vs-mb-2 vs-align-top",attrs:{"aspect":_vm.page.status === 'enabled' ? 'success': 'subtle',"iconName":"dot","variant":"icon-left"}},[_vm._v(" "+_vm._s(_vm.page.status === 'enabled' ? _vm.$t('landingpages.general.online') : _vm.$t('landingpages.general.offline'))+" ")]),(_vm.page.status === 'enabled' && _vm.page.publishedAt)?_c('span',{staticClass:"vs-mr-2 vs-mb-2 vs-body-small vs-whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('landingpages.general.publishedAt'))+" "+_vm._s(_vm._f("formatDate")(_vm.page.publishedAt,'DD MMM YYYY'))+" "+_vm._s(_vm.$t('landingpages.general.atTime'))+" "+_vm._s(_vm._f("formatDate")(_vm.page.publishedAt,'HH:mm'))+" ")]):_c('span',{staticClass:"vs-mr-2 vs-mb-2 vs-body-small vs-whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('landingpages.general.createdAt'))+" "+_vm._s(_vm._f("formatDate")(_vm.page.createdAt,'DD MMM YYYY'))+" "+_vm._s(_vm.$t('landingpages.general.atTime'))+" "+_vm._s(_vm._f("formatDate")(_vm.page.createdAt,'HH:mm'))+" ")])]},proxy:true},{key:"heading",fn:function(){return [_c('router-link',{staticClass:"vs-no-underline vs-h400 vs-align-middle vs-cursor-pointer vs-text-grey-900 vs-truncate",attrs:{"title":_vm.page.name,"to":{
                name: 'EditPage',
                params: { pageId: _vm.page._id }
            }}},[_vm._v(" "+_vm._s(_vm.page.name)+" ")]),(_vm.page.status === 'enabled')?_c('VsButton',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"url":_vm.getPublicRenderLink(_vm.page),"target":"_blank","size":"small","iconName":"link","content":_vm.$t('landingpages.pageCard.publicLink'),"aspect":"ghost","variant":"icon"}}):_vm._e()]},proxy:true},{key:"default",fn:function(){return [(_vm.page.status === 'enabled')?_c('span',{staticClass:"vs-inline-flex vs-py-1 vs-px-4 vs-gap-2 vs-items-center vs-bg-info-100 vs-mt-4"},[_c('VsIcon',{attrs:{"name":"trending-up"}}),_vm._v(" "),_c('span',{staticClass:"vs-h400"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.page.statistics ? _vm.page.statistics.visit.total : 0)))]),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v("Visite")])],1):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('VsButton',{staticClass:"vs-hidden md:vs-inline-flex vs-ml-2",attrs:{"aspect":"secondary","to":{
                name: 'EditPage',
                params: { pageId: _vm.page._id }
            }}},[_vm._v(" "+_vm._s(_vm.$t('landingpages.general.edit'))+" ")]),(_vm.page.status === 'disabled')?_c('VsButton',{staticClass:"vs-hidden md:vs-inline-flex vs-ml-2",on:{"click":function($event){return _vm.$emit('change-status', 'enabled')}}},[_vm._v(" "+_vm._s(_vm.$t('landingpages.pageActions.publish'))+" ")]):_vm._e(),_c('VsDropdownButton',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-ml-2",attrs:{"size":"medium","title":"Altre azioni"}},[_c('VsActionItem',{staticClass:"md:vs-hidden",attrs:{"to":{
                    name: 'EditPage',
                    params: { pageId: _vm.page._id }
                },"label":_vm.$t('landingpages.general.edit')}}),(_vm.page.status === 'disabled')?_c('VsActionItem',{staticClass:"md:vs-hidden",attrs:{"label":_vm.$t('landingpages.pageActions.publish')},on:{"click":function($event){return _vm.$emit('change-status', 'enabled')}}}):_vm._e(),(_vm.page.status === 'enabled')?_c('VsActionItem',{attrs:{"label":_vm.$t('landingpages.pageMenu.offline')},on:{"click":function($event){return _vm.$emit('change-status', 'disabled')}}}):_vm._e(),_c('VsActionItem',{attrs:{"label":_vm.$t('landingpages.pageMenu.duplicate')},on:{"click":function($event){return _vm.$emit('duplicate-page')}}}),_c('VsActionItem',{staticClass:"md:vs-hidden",attrs:{"label":_vm.$t('landingpages.projects.preview')},on:{"click":function($event){return _vm.$emit('preview')}}}),_c('VsActionItem',{attrs:{"label":_vm.$t('landingpages.pageMenu.sendPreview')},on:{"click":function($event){return _vm.$emit('send-page-preview')}}}),_c('VsActionItem',{attrs:{"disabled":!_vm.canExportHtml,"appendIconName":_vm.canExportHtml ? '' : 'lock',"label":_vm.$t('landingpages.pageMenu.export')},on:{"click":function($event){return _vm.$emit('export-to-zip')}}}),_c('VsActionItem',{attrs:{"aspect":"alert","label":_vm.$t('landingpages.pageMenu.delete')},on:{"click":function($event){return _vm.$emit('delete')}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }